











































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class AgendaAlcalde extends Vue {
  private win = window;
  private base = window.location;
  private menu = "";
  private menuLateral: any = [];
  private listaAgenda: any = [];
  private selectedDate: any = new Date();
  private formattedFecha = "";
  private alcalde = "";

  private mounted() {
    document.title = "Agenda Alcalde - Municipalidad de Arica";
    this.getAlcalde();
    this.formattedFecha = this.formatFechaSelected();
    this.getAgenda(this.selectedDate);
  }

  private formatFechaSelected() {
    const diasSemana = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ];
    const meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ];
    const diaSemana = diasSemana[this.selectedDate.getDay()];
    const dia = this.selectedDate.getDate();
    const mes = meses[this.selectedDate.getMonth()];
    const anio = this.selectedDate.getFullYear();

    return `${diaSemana} ${dia} de ${mes} ${anio}`;
  }

  private actualizarFecha(valor) {
    if (valor > 0) {
      this.selectedDate.setDate(this.selectedDate.getDate() + 1);
      this.formattedFecha = this.formatFechaSelected();
      this.getAgenda(this.selectedDate);
    } else {
      this.selectedDate.setDate(this.selectedDate.getDate() - 1);
      this.formattedFecha = this.formatFechaSelected();
      this.getAgenda(this.selectedDate);
    }
  }
  private getAgenda(fechaSelected) {
    const dia = String(fechaSelected.getDate()).padStart(2, "0");
    const mes = String(fechaSelected.getMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11
    const anio = fechaSelected.getFullYear();

    // Formatear la fechaSelected en "dd/mm/YYYY"
    const fechaSelectedFormateada = `${dia}/${mes}/${anio}`;
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("agenda?fecha=" + fechaSelectedFormateada)
      .then(res => {
        this.listaAgenda = res.data;
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.listaAgenda = [];
      });
  }

  private getAlcalde() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("alcalde")
      .then(res => {
        this.alcalde = res.data;
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.alcalde = "";
      });
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private async setMenu() {
    const runtimeConfig1 = await fetch("/menu.json");
    this.menu = await runtimeConfig1.json();
    for (let i = 0; i < this.menu.length; i++) {
      this.getSubMenu(this.menu[i], "concejo-municipal", 1);
    }
  }

  private getSubMenu(element: any, nombre_web: string, profundidad) {
    if (element.nombre_web == nombre_web) {
      return true;
    } else {
      if (element.sub_menu && element.sub_menu.length > 0) {
        for (let j = 0; j < element.sub_menu.length; j++) {
          if (
            this.getSubMenu(element.sub_menu[j], nombre_web, profundidad + 1)
          ) {
            if (profundidad == 2) {
              return true;
            } else {
              this.menuLateral = element.sub_menu;
              this.checkSubMenus();
              return false;
            }
          }
        }
      }
      return false;
    }
  }

  private checkSubMenus() {
    for (let i = 0; i < this.menuLateral.length; i++) {
      if (this.menuLateral[i].sub_menu.length > 0) {
        if (
          this.menuLateral[i].tipo == 2 &&
          "concejo" == this.menuLateral[i].nombre_web
        ) {
          this.menuLateral[i].expand = true;
        }
      } else {
        this.menuLateral[i].expand = false;
      }
    }
  }

  private toLink(item: any, blank: any) {
    if (item && item != "") {
      if (blank == 1) {
        window.open(item, "_blank");
      } else {
        window.open(item, "_self");
      }
    } else {
      return;
    }
  }
  private llamar(numero) {
    window.open("tel:" + numero);
  }
}
